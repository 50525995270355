import React, { useState, useEffect } from "react";
import {
  Input,
  InputNumber,
  Row,
  Col,
  Button,
  Switch,
  Divider,
  Tabs,
  Card,
  Spin,
  Tree,
  Checkbox,
  Form,
} from "antd";
import { ClaimTypeLimitTable } from "../Claim/ClaimTypeLimitTable";
import { ClaimTypeLimitForm } from "../Claim/ClaimTypeLimitForm";
import { SelectionInput } from "../../common/SelectionInput";
import PropTypes from "prop-types";
import ClaimTypeLimitModel from "../../model/Claim/ClaimTypeLimitModel";
import { LimitLevel, LimitType } from "../../model/Claim/ClaimEnum";
import { RecordType } from "../../model/common/RecordType";
import { RowState } from "../../model/common/RowState";
import EntryFormFooter from "../../common/EntryFormFooter";
import {
  inputLocaleFormatter,
  inputLocaleParser,
  stepPrecision,
  precisionDecimal,
} from "../../common/InputNumberFormatter";
import { useTranslation } from "react-i18next";

const ClaimTypeInputs = ({
  isLoading,
  claimType,
  uoms,
  reimbursementTypes,
  deductionTypes,
  getLimitInputSelectionData,
  onSubmitted,
  onCancelled,
  onDataChanged,
  form,
  companies,
  userCompCurr,
  claimConfg,
  setIsDirty,
}) => {
  const { t } = useTranslation();
  const [formClaimTypeLimit] = Form.useForm();
  const [isFormClaimtTypeInitialized, setIsFormClaimTypeInitialized] =
    useState(false);
  useEffect(
    () => formClaimTypeLimit.resetFields(),
    [isFormClaimtTypeInitialized, formClaimTypeLimit]
  );
  const formats = { cellSpacing: 20 };
  const [inputLimitLevel, setInputLimitLevel] = useState({});
  const [inputSelectionData, setInputSelectionData] = useState([]);
  const [showLimitForm, setShowLimitForm] = useState(false);
  const [selectedClaimTypeLimits, setSelectedClaimTypeLimits] = useState([]);
  const [activeTab, setActiveTab] = useState("0");

  const disableField = claimType.RcdType === RecordType.USER ? false : true;
  const company = companies ? companies : [];
  let checkedOUList =
    claimType.checkedOUList.length > 0 ? claimType.checkedOUList : [];

  function getCompLimitData() {
    return claimType.ClaimTypeLimits.filter(
      (x) =>
        x.LimitLevel === LimitLevel.COMPANY.Level &&
        x.RowState !== RowState.DELETED
    );
  }

  function getDeptLimitData() {
    return claimType.ClaimTypeLimits.filter(
      (x) =>
        x.LimitLevel === LimitLevel.DEPARTMENT.Level &&
        x.RowState !== RowState.DELETED
    );
  }

  function getGradeLimitData() {
    return claimType.ClaimTypeLimits.filter(
      (x) =>
        x.LimitLevel === LimitLevel.GRADE.Level &&
        x.RowState !== RowState.DELETED
    );
  }

  function getEmpyLimitData() {
    return claimType.ClaimTypeLimits.filter(
      (x) =>
        x.LimitLevel === LimitLevel.EMPLOYEE.Level &&
        x.RowState !== RowState.DELETED
    );
  }

  function calcClaimLimitTotal(
    limitType,
    selfAmt,
    spouseAmt,
    childAmt,
    maxChildNum
  ) {
    let temp = selectedClaimTypeLimits.map((x) => {
      return x.LimitType === limitType
        ? {
            ...x,
            MaxTotalAmt: getTotal(selfAmt, spouseAmt, childAmt, maxChildNum),
          }
        : x;
    });
    setSelectedClaimTypeLimits(temp);
  }

  function getTotal(selfAmt, spouseAmt, childAmt, maxChildNum) {
    return selfAmt + spouseAmt + childAmt * maxChildNum;
  }

  function createDefaultClaimTypeLimit(limitLevel) {
    return [
      ClaimTypeLimitModel({
        limitLevel: limitLevel,
        limitType: LimitType.TRANS,
      }),
      ClaimTypeLimitModel({
        limitLevel: limitLevel,
        limitType: LimitType.MTH,
      }),
      ClaimTypeLimitModel({
        limitLevel: limitLevel,
        limitType: LimitType.YR,
      }),
    ];
  }

  function addNewClaimTypeLimit(limitLevel) {
    setSelectedClaimTypeLimits(createDefaultClaimTypeLimit(limitLevel));
    setInputLimitLevel(limitLevel);
    setInputSelectionData(getLimitInputSelectionData(limitLevel.Level, -1));
    setShowLimitForm(true);
    setIsFormClaimTypeInitialized(!isFormClaimtTypeInitialized);
  }

  function editClaimTypeLimit(limitLevel, refKey) {
    let refLimits = claimType.ClaimTypeLimits.filter(
      (item) =>
        item.LimitLevel === limitLevel.Level &&
        item.RefKey === refKey &&
        item.RowState !== RowState.DELETED
    );
    let selectedLimit = refLimits
      ? refLimits
      : createDefaultClaimTypeLimit(limitLevel);

    setSelectedClaimTypeLimits(selectedLimit);
    setInputLimitLevel(limitLevel);
    setInputSelectionData(
      getLimitInputSelectionData(limitLevel.Level, selectedLimit[0].RefKey)
    );
    setShowLimitForm(true);
    setIsFormClaimTypeInitialized(!isFormClaimtTypeInitialized);
  }

  function deleteClaimTypeLimit(limitLevel, refKey) {
    //change state to DELETED for removal in db
    let newData = claimType.ClaimTypeLimits.map((x) => {
      return x.LimitLevel === limitLevel.Level &&
        x.RefKey === refKey &&
        x.RowState !== RowState.DELETED
        ? { ...x, RowState: RowState.DELETED }
        : x;
    }).filter(
      //exclude those new record which is added and deleted before save the form
      (y) => !(y.ClaimTypeLimitKey === -1 && y.RowState === RowState.DELETED)
    );

    onDataChanged({ ...claimType, ClaimTypeLimits: newData });
  }

  function saveClaimTypeLimit(limitLevel, values) {
    let claimLimitSubmit = selectedClaimTypeLimits.map((x) => {
      let selfAmt = 0,
        spouseAmt = 0,
        childAmt = 0,
        maxChildNum = 0,
        total = 0;
      switch (x.LimitType) {
        case LimitType.TRANS.Type:
          selfAmt = values.SelfAmtByTrans;
          spouseAmt = values.SpouseAmtByTrans;
          childAmt = values.ChildAmtByTrans;
          maxChildNum = values.MaxChildNumByTrans;
          total = getTotal(
            values.SelfAmtByTrans,
            values.SpouseAmtByTrans,
            values.ChildAmtByTrans,
            values.MaxChildNumByTrans
          );
          break;
        case LimitType.MTH.Type:
          selfAmt = values.SelfAmtByMth;
          spouseAmt = values.SpouseAmtByMth;
          childAmt = values.ChildAmtByMth;
          maxChildNum = values.MaxChildNumByMth;
          total = getTotal(
            values.SelfAmtByMth,
            values.SpouseAmtByMth,
            values.ChildAmtByMth,
            values.MaxChildNumByMth
          );
          break;
        case LimitType.YR.Type:
          selfAmt = values.SelfAmtByYr;
          spouseAmt = values.SpouseAmtByYr;
          childAmt = values.ChildAmtByYr;
          maxChildNum = values.MaxChildNumByYr;
          total = getTotal(
            values.SelfAmtByYr,
            values.SpouseAmtByYr,
            values.ChildAmtByYr,
            values.MaxChildNumByYr
          );
          break;
        default:
          break;
      }

      return {
        ...x,
        ...{
          RefKey:
            values.Ref && values.Ref.key !== undefined ? values.Ref.key : -1,
          RefDesc:
            values.Ref && values.Ref.label !== undefined
              ? values.Ref.label
              : "",
          SelfAmt: selfAmt ? selfAmt : 0,
          SpouseAmt: spouseAmt ? spouseAmt : 0,
          ChildAmt: childAmt ? childAmt : 0,
          MaxChildNum: maxChildNum ? maxChildNum : 0,
          MaxTotalAmt: total ? total : 0,
        },
      };
    });

    let prevRefKey =
      selectedClaimTypeLimits[0].RefKey !== -1
        ? selectedClaimTypeLimits[0].RefKey
        : claimLimitSubmit[0].RefKey;

    //upon save consider as MODIFIED even no changes on data, new row remain as ADDED
    if (claimLimitSubmit) {
      let index = claimType.ClaimTypeLimits.findIndex(
        (x) =>
          x.LimitLevel === limitLevel.Level &&
          x.RefKey === prevRefKey &&
          x.RowState !== RowState.DELETED
      );

      if (index !== -1) {
        let temp = claimType.ClaimTypeLimits.map((x) => {
          let item = claimLimitSubmit.find(
            (y) =>
              y.LimitLevel === x.LimitLevel &&
              x.RefKey === prevRefKey &&
              y.LimitType === x.LimitType &&
              x.RowState !== RowState.DELETED
          );

          return item
            ? item.RowState === RowState.ADDED
              ? item
              : { ...item, RowState: RowState.MODIFIED }
            : x;
        });

        onDataChanged({ ...claimType, ClaimTypeLimits: temp });
      } else {
        //row state is ADDED by default
        onDataChanged({
          ...claimType,
          ClaimTypeLimits: [...claimType.ClaimTypeLimits, ...claimLimitSubmit],
        });
      }

      setShowLimitForm(false);
    }
  }

  function cancelInputClaimTypeLimit() {
    setShowLimitForm(false);
  }

  const renderTreeNodes = (data) =>
    data.map((item) => {
      if (item.SelectionDetData) {
        return {
          title: item.CodeDesc,
          key: item.Code,
          children: renderTreeNodes(item.SelectionDetData),
        };
      }
      return {
        title: item.CodeDesc,
        key: item.Key,
      };
    });

  const onCheck = (checkedKeys) => {
    checkedOUList = [];
    for (let index = 0; index < checkedKeys.length; ++index) {
      if (/^\d+$/.test(checkedKeys[index]) === true) {
        checkedOUList.push(checkedKeys[index]);
      }
    }
    setIsDirty(true);
    onDataChanged({ ...claimType, checkedOUList: checkedOUList });
  };

  function handleSubmit() {
    form.validateFields().then((values) => {
      values["checkedOUList"] = checkedOUList;
      onSubmitted(values);
    });
  }

  function handleSubmitFailed() {
    setActiveTab("0");
  }

  return (
    <Spin spinning={isLoading}>
      <Form
        form={form}
        layout={"vertical"}
        onFinish={handleSubmit}
        onFinishFailed={handleSubmitFailed}
        onValuesChange={() => setIsDirty(true)}
      >
        <Row>
          <Col
            xs={{ span: 24, offset: 0 }}
            sm={{ span: 24, offset: 0 }}
            xl={{ span: 24, offset: 0 }}
            xxl={{ span: 16, offset: 4 }}
          >
            <Card
              title={
                <span className="form-title">
                  {t("claimTypesForm.claim_type_setup")}
                </span>
              }
              extra={
                <Form.Item
                  style={{ textAlign: "right", margin: 0 }}
                  name="Active"
                  valuePropName="checked"
                  initialValue={claimType.Active}
                >
                  <Switch
                    checkedChildren={t("general.active")}
                    unCheckedChildren={t("general.inactive")}
                  />
                </Form.Item>
              }
            >
              <Tabs
                defaultActiveKey="0"
                activeKey={activeTab}
                onChange={(value) => setActiveTab(value)}
                items={[
                  {
                    key: "0",
                    label: t("general.general"),
                    children: (
                      <>
                        <Row
                          gutter={formats.cellSpacing}
                          style={{
                            marginTop: 16,
                          }}
                        >
                          <Col xs={24} lg={12}>
                            <Form.Item
                              label={t("claimTypesForm.claim_type_code")}
                              name="ClaimTypeCode"
                              rules={[
                                {
                                  required: true,
                                  whitespace: true,
                                  message: t(
                                    "claimTypesForm.claim_type_code_required_validation"
                                  ),
                                },
                                {
                                  max: 100,
                                  message: t(
                                    "claimTypesForm.claim_type_code_max_length_validation"
                                  ),
                                },
                              ]}
                              initialValue={claimType.ClaimTypeCode}
                            >
                              <Input
                                placeholder={t(
                                  "claimTypesForm.claim_type_code_placeholder"
                                )}
                                disabled={disableField}
                              />
                            </Form.Item>
                          </Col>
                          <Col xs={24} lg={12}>
                            <Form.Item
                              label={t("general.description")}
                              name="ClaimTypeDesc"
                              rules={[
                                {
                                  required: true,
                                  whitespace: true,
                                  message: t(
                                    "general.description_required_validation"
                                  ),
                                },
                                {
                                  max: 250,
                                  message: t(
                                    "general.description_max_length_validation"
                                  ),
                                },
                              ]}
                              initialValue={claimType.ClaimTypeDesc}
                            >
                              <Input
                                placeholder={t(
                                  "general.description_placeholder"
                                )}
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                        <Row gutter={formats.cellSpacing}>
                          <Col xs={24} lg={6}>
                            <Form.Item
                              label={t("claimTypesForm.controlled_unit_cost")}
                              name="DefaultCost"
                              initialValue={claimType.DefaultCost}
                            >
                              <InputNumber
                                style={{ width: "100%" }}
                                step={stepPrecision(userCompCurr.CurrCode)}
                                min={0}
                                precision={precisionDecimal(
                                  userCompCurr.CurrCode
                                )}
                                prefix={
                                  userCompCurr.CurrSymb
                                    ? userCompCurr.CurrSymb
                                    : ""
                                }
                                formatter={inputLocaleFormatter(
                                  precisionDecimal(userCompCurr.CurrCode)
                                )}
                                parser={inputLocaleParser(
                                  precisionDecimal(userCompCurr.CurrCode)
                                )}
                              />
                            </Form.Item>
                          </Col>
                          <Col xs={24} lg={6}>
                            <Form.Item
                              label={t("claimTypesForm.unit_of_measurement")}
                              name="UOM"
                              rules={[
                                {
                                  required: true,
                                  message: t(
                                    "claimTypesForm.unit_of_measurement_required_validation"
                                  ),
                                },
                                {
                                  validator: (_, value) => {
                                    if (
                                      value &&
                                      !uoms.some((x) => x.Key === value.value)
                                    ) {
                                      return Promise.reject(
                                        new Error(
                                          t(
                                            "claimTypesForm.unit_of_measurement_invalid_validation"
                                          )
                                        )
                                      );
                                    }
                                    return Promise.resolve();
                                  },
                                },
                              ]}
                              initialValue={
                                claimType.UOMKey > 0
                                  ? {
                                      key: claimType.UOMKey,
                                      value: claimType.UOMKey,
                                      label:
                                        claimType.UOMCode +
                                        " - " +
                                        claimType.UOMDesc,
                                    }
                                  : undefined
                              }
                            >
                              <SelectionInput
                                ref={React.createRef()}
                                labelInValue={true}
                                data={uoms}
                                valueProp={"Key"}
                                textProp={"CodeDesc"}
                                disabledProp={"Active"}
                                placeholder={t(
                                  "claimTypesForm.unit_of_measurement_placeholder"
                                )}
                              />
                            </Form.Item>
                          </Col>
                          <Col xs={24} lg={12}>
                            <Form.Item
                              label={t("claimTypesForm.reimbursement_type")}
                              style={{ margin: 0 }}
                              name="ReimbursementType"
                              rules={[
                                {
                                  required: true,
                                  message: t(
                                    "claimTypesForm.reimbursement_type_required_validation"
                                  ),
                                },
                                {
                                  validator: (_, value) => {
                                    if (
                                      value &&
                                      !reimbursementTypes.some(
                                        (x) => x.Key === value.value
                                      )
                                    ) {
                                      return Promise.reject(
                                        new Error(
                                          t(
                                            "claimTypesForm.reimbursement_type_invalid_validation"
                                          )
                                        )
                                      );
                                    }
                                    return Promise.resolve();
                                  },
                                },
                              ]}
                              initialValue={
                                claimType.ReimbursementKey > 0
                                  ? {
                                      key: claimType.ReimbursementKey,
                                      value: claimType.ReimbursementKey,
                                      label:
                                        claimType.ReimbursementCode +
                                        " - " +
                                        claimType.ReimbursementDesc,
                                    }
                                  : undefined
                              }
                            >
                              <SelectionInput
                                ref={React.createRef()}
                                labelInValue={true}
                                data={reimbursementTypes}
                                valueProp={"Key"}
                                textProp={"CodeDesc"}
                                disabledProp={"Active"}
                                placeholder={t(
                                  "claimTypesForm.reimbursement_type_placeholder"
                                )}
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                        <Row gutter={formats.cellSpacing}>
                          <Col
                            xs={24}
                            lg={12}
                            style={{
                              display: "none",
                            }}
                          >
                            <Form.Item
                              label={t("claimTypesForm.deduction_type")}
                              style={{ margin: 0 }}
                              name="DeductionType"
                              rules={[
                                {
                                  required: false, //claimType.ClaimTypeCode === "MC" && claimType.RcdType === RecordType.SYSTEM
                                  message: t(
                                    "claimTypesForm.deduction_type_required_validation"
                                  ),
                                },
                              ]}
                              initialValue={
                                claimType.DeductionKey > 0
                                  ? {
                                      key: claimType.DeductionKey,
                                      label:
                                        claimType.DeductionCode +
                                        " - " +
                                        claimType.DeductionDesc,
                                    }
                                  : undefined
                              }
                            >
                              <SelectionInput
                                ref={React.createRef()}
                                labelInValue={true}
                                data={deductionTypes}
                                valueProp={"Key"}
                                textProp={"CodeDesc"}
                                disabledProp={"Active"}
                                placeholder={t(
                                  "claimTypesForm.deduction_type_placeholder"
                                )}
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                        <Row gutter={formats.cellSpacing}>
                          <Col xs={24} lg={6}>
                            <Form.Item
                              label=""
                              style={{ margin: 0 }}
                              name="IsMedicalClaim"
                              valuePropName="checked"
                              initialValue={claimType.IsMedicalClaim}
                            >
                              <Checkbox disabled={disableField}>
                                {t("claimTypesForm.medical_claim")}
                              </Checkbox>
                            </Form.Item>
                          </Col>
                          <Col
                            xs={24}
                            lg={6}
                            style={{
                              display:
                                claimConfg && claimConfg.IsGroupByProj === true
                                  ? ""
                                  : "none",
                            }}
                          >
                            <Form.Item
                              label=""
                              style={{ margin: 0 }}
                              name="IsBillable"
                              valuePropName="checked"
                              initialValue={claimType.IsBillable}
                            >
                              <Checkbox disabled={disableField}>
                                {t("claimTypesForm.billable")}
                              </Checkbox>
                            </Form.Item>
                          </Col>
                          <Col xs={24} lg={6}>
                            <Form.Item
                              label=""
                              style={{ margin: 0 }}
                              name="IsClaimable"
                              valuePropName="checked"
                              initialValue={claimType.IsClaimable}
                            >
                              <Checkbox disabled={disableField}>
                                {t("claimTypesForm.claimable")}
                              </Checkbox>
                            </Form.Item>
                          </Col>
                          <Col xs={24} lg={6}>
                            <Form.Item
                              label=""
                              style={{ margin: 0 }}
                              name="IsRequiredAuth"
                              valuePropName="checked"
                              initialValue={claimType.IsRequiredAuth}
                            >
                              <Checkbox disabled={disableField}>
                                {t(
                                  "claimTypesForm.travel_require_authorisation"
                                )}
                              </Checkbox>
                            </Form.Item>
                          </Col>
                        </Row>
                        <Divider />

                        <h3 className="form-subtitle">
                          {t("claimTypesForm.claim_limits")}
                        </h3>
                        <Tabs
                          defaultActiveKey={1}
                          items={[
                            {
                              key: LimitLevel.COMPANY.Level,
                              label: t("general." + LimitLevel.COMPANY.Desc),
                              children: (
                                <ClaimTypeLimitTable
                                  limitLevel={LimitLevel.COMPANY}
                                  data={getCompLimitData()}
                                  onAdded={addNewClaimTypeLimit}
                                  onEdited={editClaimTypeLimit}
                                  onDeleted={deleteClaimTypeLimit}
                                  userCompCurr={userCompCurr}
                                />
                              ),
                            },
                            {
                              key: LimitLevel.DEPARTMENT.Level,
                              label: t("general." + LimitLevel.DEPARTMENT.Desc),
                              children: (
                                <ClaimTypeLimitTable
                                  limitLevel={LimitLevel.DEPARTMENT}
                                  data={getDeptLimitData()}
                                  onAdded={addNewClaimTypeLimit}
                                  onEdited={editClaimTypeLimit}
                                  onDeleted={deleteClaimTypeLimit}
                                  userCompCurr={userCompCurr}
                                />
                              ),
                            },
                            {
                              key: LimitLevel.GRADE.Level,
                              label: t("general." + LimitLevel.GRADE.Desc),
                              children: (
                                <ClaimTypeLimitTable
                                  limitLevel={LimitLevel.GRADE}
                                  data={getGradeLimitData()}
                                  onAdded={addNewClaimTypeLimit}
                                  onEdited={editClaimTypeLimit}
                                  onDeleted={deleteClaimTypeLimit}
                                  userCompCurr={userCompCurr}
                                />
                              ),
                            },
                            {
                              key: LimitLevel.EMPLOYEE.Level,
                              label: t("general." + LimitLevel.EMPLOYEE.Desc),
                              children: (
                                <ClaimTypeLimitTable
                                  limitLevel={LimitLevel.EMPLOYEE}
                                  data={getEmpyLimitData()}
                                  onAdded={addNewClaimTypeLimit}
                                  onEdited={editClaimTypeLimit}
                                  onDeleted={deleteClaimTypeLimit}
                                  userCompCurr={userCompCurr}
                                />
                              ),
                            },
                          ]}
                        ></Tabs>

                        <ClaimTypeLimitForm
                          isVisible={showLimitForm}
                          limitLevel={inputLimitLevel}
                          claimTypeLimits={selectedClaimTypeLimits}
                          onSaved={saveClaimTypeLimit}
                          onCancelled={cancelInputClaimTypeLimit}
                          onLimitAmtChanged={calcClaimLimitTotal}
                          userCompCurr={userCompCurr}
                          selectionData={inputSelectionData}
                          setIsDirty={setIsDirty}
                          form={formClaimTypeLimit}
                        />
                      </>
                    ),
                  },
                  {
                    key: "1",
                    label: t("general.permission"),
                    children: (
                      <Tree
                        checkable
                        defaultExpandAll={true}
                        defaultCheckedKeys={
                          claimType.checkedOUList.length > 0
                            ? claimType.checkedOUList
                            : []
                        }
                        onCheck={onCheck}
                        treeData={renderTreeNodes(company)}
                      ></Tree>
                    ),
                  },
                ]}
              ></Tabs>
            </Card>
            <Row className="save-section">
              <Col
                style={{
                  textAlign: "right",
                  padding: "10px",
                }}
              >
                <Button
                  size="large"
                  type="default"
                  onClick={onCancelled}
                  style={{ marginRight: "5px" }}
                >
                  {t("general.cancel")}
                </Button>
                <Button size="large" type="primary" htmlType="submit">
                  {t("general.save")}
                </Button>
              </Col>
            </Row>
            <EntryFormFooter
              footerData={claimType}
              showFooter={claimType.ClaimTypeKey !== -1 ? true : false}
              isApproved={false}
              isVoid={false}
              isPending={false}
              t={t}
            ></EntryFormFooter>
          </Col>
        </Row>
      </Form>
    </Spin>
  );
};

ClaimTypeInputs.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  claimType: PropTypes.object.isRequired,
  uoms: PropTypes.array.isRequired,
  reimbursementTypes: PropTypes.array.isRequired,
  deductionTypes: PropTypes.array.isRequired,
  getLimitInputSelectionData: PropTypes.func.isRequired,
  onSubmitted: PropTypes.func.isRequired,
  onCancelled: PropTypes.func.isRequired,
  onDataChanged: PropTypes.func.isRequired,
  companies: PropTypes.array.isRequired,
  userCompCurr: PropTypes.object.isRequired,
  claimConfg: PropTypes.object.isRequired,
  setIsDirty: PropTypes.func.isRequired,
  form: PropTypes.object.isRequired,
};

export const ManageClaimTypeForm = ClaimTypeInputs;
