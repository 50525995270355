import React, { useEffect, useState } from "react";
import {
  ArrowLeftOutlined,
  CheckCircleOutlined,
  EditOutlined,
  LockOutlined,
  LoadingOutlined,
  SolutionOutlined,
  CloseCircleOutlined,
} from "@ant-design/icons";
import {
  Input,
  Button,
  Typography,
  Row,
  Col,
  Alert,
  Steps,
  Result,
  Form,
} from "antd";
import {
  validateResetPasswordLink,
  resetPasswordThruLink,
} from "../../api/forgotPasswordApi";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

const { Paragraph, Title } = Typography;

function ExtendPage() {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [data] = useState({ password: "", confirmPassword: "" });
  const [stepState, setStepState] = useState(0);
  const [showInvalidURL, setShowInvalidURL] = useState(false);
  const [isLoadingIcon, setIsLoadingIcon] = useState("loading");
  const [verificationStatus, setVerificationStatus] = useState("process");
  const [isLoading, setIsLoading] = useState(false);
  const [showError, setShowError] = useState(false);
  const [showErrorMsg, setShowErrorMsg] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [confirmDirty, setConfirmDirty] = useState(false);
  const { resetPassword } = useParams();

  const iconEnum = {
    loading: <LoadingOutlined />,
    solution: <SolutionOutlined />,
    close: <CloseCircleOutlined />,
  };

  useEffect(() => {
    let resetPasswordEncrypted = resetPassword;

    if (resetPasswordEncrypted !== "") {
      validateResetPasswordLink(resetPasswordEncrypted)
        .then(({ data }) => {
          setUserEmail(data);
          setIsLoadingIcon("solution");
          setVerificationStatus("finish");
          setStepState(1);
        })
        .catch(() => {
          setIsLoadingIcon("close");
          setVerificationStatus("error");
          setShowInvalidURL(true);
        });
    }
  }, [resetPassword]);

  const handleSubmitFailed = () => {
    setIsLoading(false);
  };

  const handleSubmit = async () => {
    const values = await form.validateFields();
    if (values.password !== values.confirmPassword) {
      setIsLoading(false);
      setShowErrorMsg(t("forgotPasswordForm.password_not_match_validation"));
      setShowError(true);
    } else if (values && !values.password.match(/[a-z]/)) {
      setIsLoading(false);
      setShowErrorMsg(
        t("forgotPasswordForm.password_1_small_letter_required_validation")
      );
      setShowError(true);
    } else if (values && !values.password.match(/[A-Z]/)) {
      setIsLoading(false);
      setShowErrorMsg(
        t("forgotPasswordForm.password_1_capital_letter_required_validation")
      );
      setShowError(true);
    } else if (values && !values.password.match(/\d/)) {
      setIsLoading(false);
      setShowErrorMsg(
        t("forgotPasswordForm.password_1_number_required_validation")
      );
      setShowError(true);
    } else if (values && values.password.length < 6) {
      setIsLoading(false);
      setShowErrorMsg(
        t("forgotPasswordForm.password_6_characters_required_validation")
      );
      setShowError(true);
    } else {
      resetPasswordThruLink(userEmail, values.confirmPassword)
        .then(() => {
          setStepState(2);
        })
        .catch(({ response }) => {
          setIsLoading(false);
          setShowErrorMsg(response.data.ExceptionMessage);
          setShowError(true);
        });
    }
  };

  function enterLoading() {
    setIsLoading(true);
  }

  function handleClose() {
    setShowErrorMsg("");
    setShowError(false);
  }

  function handleConfirmBlur(e) {
    const { value } = e.target;
    setConfirmDirty(confirmDirty || !!value);
  }

  const validateToNextPassword = (_, value) => {
    if (value && !value.match(/[a-z]/)) {
      return Promise.reject(
        new Error(
          t("forgotPasswordForm.password_1_small_letter_required_validation")
        )
      );
    } else if (value && !value.match(/[A-Z]/)) {
      return Promise.reject(
        new Error(
          t("forgotPasswordForm.password_1_capital_letter_required_validation")
        )
      );
    } else if (value && !value.match(/\d/)) {
      return Promise.reject(
        new Error(t("forgotPasswordForm.password_1_number_required_validation"))
      );
    } else if (value && value.length < 6) {
      return Promise.reject(
        new Error(
          t("forgotPasswordForm.password_6_characters_required_validation")
        )
      );
    }

    return Promise.resolve();
  };

  return (
    <Row
      style={{
        backgroundImage: "linear-gradient(#edf5fa, #c3cfe2)",
        minHeight: "100vh",
      }}
    >
      <Col
        xs={{ span: 24, offset: 0 }}
        sm={{ span: 12, offset: 6 }}
        xl={{ span: 8, offset: 8 }}
        style={{}}
      >
        <div
          className="loginContainer"
          style={{
            width: "400px",
            backgroundColor: "#fff",
            borderRadius: "10px",
          }}
        >
          <div style={{ textAlign: "center" }}>
            <img
              src={`https://quartoblobv2.blob.core.windows.net/shared/image/ess/ESS-logo.png`}
              width="250px"
              alt="logo"
              style={{ marginTop: "50px" }}
            />
          </div>

          <div
            style={{
              marginTop: 5,
              marginBottom: 40,
              fontSize: 16,
              color: "rgba(0, 0, 0, 0.45)",
              textAlign: "center",
            }}
          ></div>

          <Steps
            size="small"
            current={stepState}
            items={[
              {
                status: { verificationStatus },
                title: t("forgotPasswordForm.verification"),
                icon: iconEnum[isLoadingIcon],
              },
              {
                title: t("forgotPasswordForm.password"),
                icon: <EditOutlined />,
              },
              {
                title: t("forgotPasswordForm.done"),
                icon: <CheckCircleOutlined />,
              },
            ]}
            style={{ maxWidth: 340, margin: "0px auto" }}
          ></Steps>
          <div style={{ maxWidth: 340, margin: "0px auto" }}>
            {showInvalidURL ? (
              <Result
                status="error"
                title={t("forgotPasswordForm.invalid_url")}
                subTitle={t("forgotPasswordForm.invalid_url_message")}
              />
            ) : !showInvalidURL && stepState === 1 ? (
              <Form
                form={form}
                onFinish={handleSubmit}
                onFinishFailed={handleSubmitFailed}
                className="login-form"
              >
                <Form.Item>
                  <Title level={4} style={{ textAlign: "center" }}>
                    {t("forgotPasswordForm.change_new_password")}
                  </Title>
                </Form.Item>
                <Paragraph style={{ textAlign: "center" }}>
                  {t("general.email")} : {userEmail}
                </Paragraph>
                {showError ? (
                  <Alert
                    message={showErrorMsg}
                    type="error"
                    afterClose={handleClose}
                    showIcon
                    style={{ marginBottom: 24 }}
                  />
                ) : null}
                <Form.Item
                  name={"password"}
                  hasFeedback
                  rules={[
                    {
                      required: true,
                      message: t(
                        "forgotPasswordForm.new_password_required_validation"
                      ),
                    },
                    {
                      validator: validateToNextPassword,
                    },
                  ]}
                  initialValue={data.password}
                >
                  <Input.Password
                    placeholder={t(
                      "forgotPasswordForm.new_password_placeholder"
                    )}
                    size="large"
                    prefix={
                      <LockOutlined style={{ color: "rgba(0,0,0,.25)" }} />
                    }
                    onChange={handleClose}
                  />
                </Form.Item>
                <Form.Item
                  name={"confirmPassword"}
                  dependencies={["password"]}
                  hasFeedback
                  rules={[
                    {
                      required: true,
                      message: t(
                        "forgotPasswordForm.new_password_confirmation_validation"
                      ),
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue("password") === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          new Error(
                            t(
                              "forgotPasswordForm.password_inconsistent_message"
                            )
                          )
                        );
                      },
                    }),
                  ]}
                  initialValue={data.confirmPassword}
                >
                  <Input.Password
                    placeholder={t(
                      "forgotPasswordForm.confirm_new_password_placeholder"
                    )}
                    size="large"
                    prefix={
                      <LockOutlined style={{ color: "rgba(0,0,0,.25)" }} />
                    }
                    onChange={handleClose}
                    onBlur={handleConfirmBlur}
                  />
                </Form.Item>
                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    loading={isLoading}
                    onClick={enterLoading}
                    style={{
                      width: "100%",
                      height: 40,
                      fontSize: 16,
                    }}
                  >
                    {t("forgotPasswordForm.change_password")}
                  </Button>
                </Form.Item>
              </Form>
            ) : (
              <div>
                <Title level={4} style={{ textAlign: "center" }}>
                  {t("forgotPasswordForm.reset_your_password")}
                </Title>
                <Paragraph style={{ textAlign: "center" }}>
                  {t("forgotPasswordForm.reset_password_successfully")}
                </Paragraph>
                <div style={{ marginBottom: 14, paddingBottom: 14 }}>
                  <a href="/login" style={{ float: "left" }}>
                    <ArrowLeftOutlined />
                    {t("general.back_to_login")}
                  </a>
                </div>
              </div>
            )}
          </div>

          <div style={{ textAlign: "center", paddingBottom: "25px" }}>
            <br></br>
            {t("general.copyright")}
          </div>
        </div>
      </Col>
    </Row>
  );
}

export default ExtendPage;
