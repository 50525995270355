import React, { useState } from "react";
import PropTypes from "prop-types";
import "./HeaderNav.scss";
import { withRouter } from "../../customHooks/withRouter";
import SideBar from "../SideBar/SideBar";
import NoticeIcon from "../NoticeIcon/NoticeIcon";
import SupportIcon from "../SupportIcon/SupportIcon";
import CalendarIcon from "../CalendarIcon/CalendarIcon";
import { stopSignalR } from "../../service/signalRService";
import { Outlet, useNavigate } from "react-router-dom";
import ReloginPage from ".././../components/pages/ReloginPage";
import IdleTimer from "react-idle-timer";
import { useNavigatingAway } from "./../../customHooks/useNavigatingAway";
import Icon, {
  LogoutOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
} from "@ant-design/icons";
import {
  Layout,
  Divider,
  Tooltip,
  Button,
  Drawer,
  Modal,
  ConfigProvider,
} from "antd";
import { useTranslation } from "react-i18next";
// import i18next from "i18next";
// import enUS from "antd/locale/en_US";
// import idID from "antd/locale/id_ID";
// import msMY from "antd/locale/ms_MY";
import dayjs from "dayjs";
// import "dayjs/locale/id";
// import "dayjs/locale/ms-my";

const { Header, Sider, Content } = Layout;

function HeaderNav({ history, isDirty, setIsDirty }) {
  const [showDialogLeavingPage, confirmNavigation, cancelNavigation] =
    useNavigatingAway(isDirty, setIsDirty);
  const navigate = useNavigate();
  const [collapsed, setCollapsed] = useState(false);
  const [showLoginModal, setShowLoginModal] = useState(false);
  // const antLocale = (i18nLocale) => {
  //   if (i18nLocale === "my") {
  //     return msMY;
  //   } else if (i18nLocale === "id") {
  //     return idID;
  //   } else {
  //     return enUS;
  //   }
  // };
  // const dayjsLocale = (i18nLocale) => {
  //   if (i18nLocale === "my") {
  //     return "ms-my";
  //   } else if (i18nLocale === "id") {
  //     return "id";
  //   } else {
  //     return "en";
  //   }
  // };
  // dayjs.locale(dayjsLocale(localStorage.getItem("i18nextLng") || "en"));
  // const [locale, setLocal] = useState(
  //   antLocale(localStorage.getItem("i18nextLng") || "en")
  // );
  const { t } = useTranslation();

  function toggle() {
    setCollapsed(!collapsed);
  }

  function handleLogoutClick() {
    sessionStorage.removeItem("auth");
    sessionStorage.removeItem("localUserKey");
    stopSignalR();
    navigate("/login");
  }

  function handleLogoClick() {
    navigate("/home");
  }

  function getDevice() {
    var x = window.matchMedia("screen and (max-width: 400px)");
    if (x.matches) {
      return "Mobile";
    }
    var y = window.matchMedia(
      "screen and (min-width: 401px) and (max-width: 960px)"
    );
    if (y.matches) {
      return "Tablet";
    }
    var z = window.matchMedia("screen and (min-width: 961px)");
    if (z.matches) {
      return "Desktop";
    }
  }

  function onIdle() {
    setShowLoginModal(true);
  }

  function isLMStaff() {
    let auth = JSON.parse(sessionStorage.getItem("auth"));
    if (auth) {
      return auth.IsLMStaff;
    } else return "false";
  }

  const device = getDevice();

  // const languageMap = [
  //   {
  //     key: "en",
  //     label: "English",
  //   },
  //   {
  //     key: "my",
  //     label: "Malay",
  //   },
  //   {
  //     key: "id",
  //     label: "Indonesian",
  //   },
  // ];

  // const handleLanguageMenuClick = (e) => {
  //   i18next.changeLanguage(e.key);
  //   setLocal(antLocale(e.key));
  //   dayjs.locale(dayjsLocale(e.key));
  // };

  // const menuProps = {
  //   items: languageMap,
  //   onClick: handleLanguageMenuClick,
  // };

  return (
    <>
      <IdleTimer
        element={document}
        onIdle={onIdle}
        debounce={250}
        timeout={1000 * 60 * 60}
      />
      <ReloginPage
        showLoginModal={showLoginModal}
        setShowLoginModal={setShowLoginModal}
        history={history}
      />

      <Modal
        title={t("headerNav.unsaved_changes")}
        open={showDialogLeavingPage}
        onOk={confirmNavigation}
        okText={t("headerNav.discard")}
        onCancel={cancelNavigation}
      >
        <p>{t("headerNav.discard_confirmation")}</p>
      </Modal>
      {/* locale={locale}  <-- set this to ConfigProvider to change locale*/}
      <ConfigProvider>
        <Layout style={{ height: "100vh" }}>
          <Header
            style={{
              position: "fixed",
              zIndex: 1,
              width: "100%",
              background: "#ffffff",
              padding: 0,
              color: "#666",
              borderBottom: "1px solid #e9ebf0",
            }}
          >
            <div style={{ float: "left", overflow: "hidden" }}>
              <Icon
                style={{ fontSize: 20, padding: 22 }}
                component={collapsed ? MenuUnfoldOutlined : MenuFoldOutlined}
                onClick={toggle}
              />
            </div>

            <img
              src={`https://quartoblobv2.blob.core.windows.net/shared/image/ess/ESS-logo.png`}
              width="160px"
              alt="logo"
              style={{ marginTop: "24px", cursor: "pointer" }}
              onClick={handleLogoClick}
              className="logo_size"
            />

            <div style={{ float: "right", overflow: "hidden" }}>
              {isLMStaff() === "true" ? <SupportIcon /> : null}
              {isLMStaff() === "true" ? <Divider type="vertical" /> : null}

              <CalendarIcon dayjs={dayjs} />
              <Divider type="vertical" />

              <NoticeIcon history={history} />
              <Divider type="vertical" />

              {/* <Dropdown
                trigger={["click"]}
                menu={menuProps}
                placement="bottomLeft"
              >
                <Button style={{ boxShadow: "none", border: "none" }}>
                  <Space>
                    {t("headerNav.language")}
                    <CaretDownOutlined />
                  </Space>
                </Button>
              </Dropdown> */}

              <span
                style={{
                  display: "inline-block",
                  height: "100%",
                  padding: "0 6px",
                  cursor: "pointer",
                  transition: "all .3s",
                }}
              >
                <Tooltip placement="bottomRight" title={t("headerNav.log_out")}>
                  <Button
                    style={{ fontSize: 16, lineHeight: 0, color: "#666" }}
                    type="link"
                    size="large"
                    name="logut"
                    icon={<LogoutOutlined />}
                    onClick={handleLogoutClick}
                  />
                </Tooltip>
              </span>
            </div>
          </Header>

          {/* This set for desktop */}
          {device === "Desktop" ? (
            <>
              <Layout style={{ backgroundColor: "#e9ebf0" }}>
                <div>
                  <Sider
                    style={{
                      height: "calc(100vh)",
                      overflowY: "auto",
                      overflowX: "hidden",
                    }}
                    trigger={null}
                    collapsible
                    collapsed={collapsed}
                    width={300}
                    theme="light"
                  >
                    <div className="logo" />
                    <SideBar isCollapsed={collapsed} history={history} />
                  </Sider>
                </div>
                <Content
                  id="scroll-container"
                  style={{
                    padding: "16px",
                    marginTop: "60px",
                    background: "#e9ebf0",
                    position: "relative",
                    overflowX: "hidden",
                  }}
                >
                  <Outlet />
                </Content>
              </Layout>
            </>
          ) : null}

          {/* This set for mobile */}
          {device === "Mobile" || device === "Tablet" ? (
            <>
              <Drawer
                placement="left"
                open={collapsed}
                width="75%"
                closable={false}
                onClose={toggle}
                bodyStyle={{
                  padding: 0,
                  backgroundImage: "linear-gradient(to bottom,#126db3,#0064ae)",
                }}
              >
                <Header
                  style={{
                    zIndex: 1,
                    width: "100%",
                    backgroundImage:
                      "linear-gradient(to bottom,#126db3,#0064ae)",
                    padding: 0,
                    color: "#ccc",
                  }}
                >
                  <img
                    src={`https://quartoblobv2.blob.core.windows.net/shared/image/ess/ESS_logo.png`}
                    width="180px"
                    alt="logo"
                    style={{ marginLeft: "25px" }}
                  />
                </Header>
                <SideBar history={history} />
              </Drawer>
              <Content
                id="scroll-container"
                style={{
                  marginTop: "60px",
                  padding: "16px",
                  background: "#e9ebf0",
                  position: "relative",
                  overflowX: "hidden",
                }}
              >
                <Outlet />
              </Content>
            </>
          ) : null}
        </Layout>
      </ConfigProvider>
    </>
  );
}

HeaderNav.propTypes = {
  history: PropTypes.func.isRequired,
  isDirty: PropTypes.bool.isRequired,
  setIsDirty: PropTypes.func.isRequired,
};

export default withRouter(HeaderNav);
