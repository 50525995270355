import React, { useState } from "react";
import LoginForm from "../forms/LoginForm";
import { useNavigate } from "react-router-dom";
import { login, getClient } from "../../api/userApi";

function LoginPage() {
  const [data] = useState({
    email: "",
    password: "",
    rememberMe: "",
    client: 1,
    isValidated: false,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [showErrMsg, setShowErrMsg] = useState(false);
  const [showClient, setShowClient] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [clientData, setClientData] = useState([]);
  const [isValidated, setIsValidated] = useState(false);
  const navigate = useNavigate();

  function handleClose() {
    setShowErrMsg(false);
  }

  function enterLoading() {
    setIsLoading(true);
  }

  const handleSubmitFailed = () => {
    setIsLoading(false);
  };

  function submit(data) {
    setIsLoading(true);
    login(data)
      .then(() => {
        navigate("/home", { replace: true });
      })
      .catch((err) => {
        if (err.response.data.error === "clientIsRequired") {
          setErrorMessage("");
          setIsLoading(false);
          setShowErrMsg(false);
          setShowClient(true);
          setIsValidated(true);

          getClient(data.email, err.response.data.error_description)
            .then((result) => {
              setClientData(result);
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          setErrorMessage(err.response.data.error_description);
          setIsLoading(false);
          setShowErrMsg(true);
          setShowClient(false);
          setIsValidated(false);
        }
      });
  }

  return (
    <LoginForm
      submit={submit}
      data={data}
      isLoading={isLoading}
      errorMessage={errorMessage}
      showErrMsg={showErrMsg}
      showClient={showClient}
      isValidated={isValidated}
      clientData={clientData}
      handleSubmitFailed={handleSubmitFailed}
      handleClose={handleClose}
      enterLoading={enterLoading}
    />
  );
}

export default LoginPage;
