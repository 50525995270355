import React, { useState, useEffect } from "react";
import {
  PlusCircleOutlined,
  StarFilled,
  InfoCircleTwoTone,
} from "@ant-design/icons";
import { Spin, Card, Row, Col, Badge, Button, Tooltip } from "antd";
import { Link } from "react-router-dom";
import { PropTypes } from "prop-types";
import "../../../styles/progressRing.scss";
import { regionLocale } from "../../common/InputNumberFormatter";
import { useTranslation } from "react-i18next";

export default function LeaveApplicationWidget({ isLoading, userLeaveInfo }) {
  const { t } = useTranslation();

  const pieAL = Math.floor(
    ((userLeaveInfo.ALTotal - userLeaveInfo.ALRemain) / userLeaveInfo.ALTotal) *
      100
  );

  const utilisedLeave = Math.floor(
    userLeaveInfo.ALTotal -
      userLeaveInfo.ALRemain -
      userLeaveInfo.ALExpiredCarryForward
  );

  const progressALClass = "pie-wrapper progress-" + pieAL;

  const [annualLeaveUtilized, setAnnualLeaveUtilized] = useState(0);
  const [isAnnualLeaveUtilised, setIsAnnualLeaveUtilised] = useState(false);

  useEffect(() => {
    setIsAnnualLeaveUtilised(
      userLeaveInfo.ALTotal - userLeaveInfo.ALRemain !== 0
    );
    // setIsMedicalLeaveUtilised(
    //   userLeaveInfo.MCEntitled - userLeaveInfo.MCRemain !== 0
    // );
    setAnnualLeaveUtilized(userLeaveInfo.ALTotal - userLeaveInfo.ALRemain);
    //setMedicalLeaveUtilized(userLeaveInfo.MCEntitled - userLeaveInfo.MCRemain);
  }, [userLeaveInfo]);

  return (
    <Card
      title={
        <span style={{ color: "#0070cc" }}>
          {t("leaveApplicationWidget.leave_application")}
        </span>
      }
      className="dashboard-card"
      extra={
        <Link to={{ pathname: "leaveapplicationdisplay" }}>
          <i>{t("leaveApplicationWidget.more")}</i>
        </Link>
      }
    >
      <Spin
        style={{ display: isLoading ? "block" : "none", marginTop: "150px" }}
      />
      <Row style={{ display: !isLoading ? "flex" : "none" }}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <Row type="flex" justify="center">
            <Col span={24} style={{ textAlign: "center", padding: "10px 0" }}>
              <Badge
                count={
                  <StarFilled
                    style={
                      isAnnualLeaveUtilised
                        ? { display: "none" }
                        : { color: "rgba(52, 152, 219,1)" }
                    }
                  />
                }
              >
                <div className="pie-container">
                  <div className="pie-content-wrapper">
                    <span className="pie-text-secondary">
                      {annualLeaveUtilized.toLocaleString(regionLocale(), {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 1,
                      })}
                    </span>
                    <span className="pie-text">/</span>
                    <span className="pie-text-primary">
                      {userLeaveInfo.ALTotal.toLocaleString(regionLocale(), {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 1,
                      })}
                    </span>
                    <br />
                    <span className="pie-sub-text-secondary">
                      {t("leaveApplicationWidget.days_utilised")}
                    </span>
                    <br />
                    <div
                      style={{
                        margin: "auto",
                        width: "50px",
                        padding: "7px 0",
                        textAlign: "center",
                      }}
                    >
                      <span className="pie-title-primary">
                        {t("leaveApplicationWidget.annual_leave")}
                      </span>
                    </div>
                  </div>
                  <div className={progressALClass}>
                    <div className="shadow"></div>
                    <div className="pie" data-anim="base wrapper">
                      <div className="half-circle" data-anim="base left"></div>
                      <div className="half-circle" data-anim="base right"></div>
                    </div>
                  </div>
                </div>
              </Badge>
              &nbsp;&nbsp;&nbsp;&nbsp;
            </Col>
          </Row>
          <Row style={{ height: "190px", overflowY: "auto" }}>
            <Col span={24}>
              <table
                style={{
                  textAlign: "left",
                  margin: "10px 10px 25px",
                  lineHeight: "1.1",
                }}
              >
                <tbody>
                  <tr>
                    <td>
                      <center>
                        [<span style={{ color: "green" }}>+</span>]
                      </center>
                    </td>
                    <td>
                      {t("leaveApplicationWidget.leave_entitle_this_year")}
                    </td>
                    <td>&nbsp;:&nbsp;</td>
                    <td
                      className="dashboard-label"
                      colSpan="3"
                      style={{ textAlign: "right" }}
                    >
                      {userLeaveInfo.ALEntitled.toLocaleString(regionLocale(), {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 1,
                      })}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <center>
                        [<span style={{ color: "green" }}>+</span>]
                      </center>
                    </td>
                    <td>
                      {t(
                        "leaveApplicationWidget.leave_brought_forward_this_year"
                      )}
                    </td>
                    <td>&nbsp;:&nbsp;</td>
                    <td
                      className="dashboard-label"
                      colSpan="3"
                      style={{ textAlign: "right" }}
                    >
                      {userLeaveInfo.ALCarryForward.toLocaleString(
                        regionLocale(),
                        {
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 1,
                        }
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <center>
                        [<span style={{ color: "green" }}>+</span>]
                      </center>
                    </td>
                    <td>{t("leaveApplicationWidget.adjustment")}</td>
                    <td>&nbsp;:&nbsp;</td>
                    <td
                      className="dashboard-label"
                      colSpan="3"
                      style={{ textAlign: "right" }}
                    >
                      {userLeaveInfo.ALAdjustment.toLocaleString(
                        regionLocale(),
                        {
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 1,
                        }
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td></td>
                    <td>{t("leaveApplicationWidget.total_leave")}</td>
                    <td>&nbsp;:&nbsp;</td>
                    <td
                      className="dashboard-label"
                      colSpan="3"
                      style={{ textAlign: "right" }}
                    >
                      <b>
                        {userLeaveInfo.ALTotal.toLocaleString(regionLocale(), {
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 1,
                        })}
                      </b>
                    </td>
                  </tr>
                  <tr>
                    <td></td>
                  </tr>
                  <tr>
                    <td></td>
                  </tr>
                  <tr>
                    <td>
                      <center>
                        [<span style={{ color: "red" }}>-</span>]
                      </center>
                    </td>
                    <td>{t("leaveApplicationWidget.utilised_leave")}</td>
                    <td>&nbsp;:&nbsp;</td>
                    <td
                      className="dashboard-label"
                      colSpan="3"
                      style={{ textAlign: "right" }}
                    >
                      {utilisedLeave.toLocaleString(regionLocale(), {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 1,
                      })}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <center>
                        [<span style={{ color: "red" }}>-</span>]
                      </center>
                    </td>
                    <td>
                      {t("leaveApplicationWidget.expired_brought_forward")}
                      <Tooltip
                        title={t(
                          "leaveApplicationWidget.if_unused_by_expiry_month"
                        )}
                      >
                        <InfoCircleTwoTone style={{ marginLeft: 10 }} />
                      </Tooltip>
                    </td>
                    <td>&nbsp;:&nbsp;</td>
                    <td
                      className="dashboard-label"
                      colSpan="3"
                      style={{ textAlign: "right" }}
                    >
                      {userLeaveInfo.ALExpiredCarryForward.toLocaleString(
                        regionLocale(),
                        {
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 1,
                        }
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td></td>
                    <td>{t("leaveApplicationWidget.remaining_balance")}</td>
                    <td>&nbsp;:&nbsp;</td>
                    <td
                      className="dashboard-label"
                      colSpan="3"
                      style={{ textAlign: "right" }}
                    >
                      <b>
                        {userLeaveInfo.ALRemain.toLocaleString(regionLocale(), {
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 1,
                        })}
                      </b>
                    </td>
                  </tr>
                </tbody>
              </table>
            </Col>
          </Row>
        </Col>
        <div
          className="dashboard-card-button"
          style={{
            textAlign: "right",
          }}
        >
          <Link to={{ pathname: "leaveapplication/leavetrans" }}>
            <Button type="primary" icon={<PlusCircleOutlined />}>
              {t("leaveApplicationWidget.new_leave")}
            </Button>
          </Link>
        </div>
      </Row>
    </Card>
  );
}

LeaveApplicationWidget.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  userLeaveInfo: PropTypes.object.isRequired,
};
