import React, { useState } from "react";
import { ArrowLeftOutlined, UserOutlined } from "@ant-design/icons";
import { Row, Col, Input, Button, Typography, Alert, Form } from "antd";
import {
  isUserExists,
  sendResetPasswordEmail,
} from "../../api/forgotPasswordApi";
import { useTranslation } from "react-i18next";

const { Paragraph, Title } = Typography;

const ForgotPasswordForm = () => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [data, setData] = useState({ email: "" });
  const [isLoading, setIsLoading] = useState(false);
  const [showErrorMsg, setShowErrorMsg] = useState(false);
  const [showSuccessMsg, setShowSuccessMsg] = useState(false);

  const handleSubmitFailed = () => {
    setIsLoading(false);
  };

  const handleSubmit = async () => {
    const values = await form.validateFields();
    setIsLoading(true);
    isUserExists(values.email)
      .then(() => {
        setIsLoading(false);
        setShowSuccessMsg(true);
        setData(values);
        sendResetPassword(values.email);
      })
      .catch(() => {
        setIsLoading(false);
        setShowErrorMsg(true);
      });
  };

  function handleClose() {
    setShowErrorMsg(false);
  }

  function enterLoading() {
    setIsLoading(true);
  }

  function sendResetPassword(email) {
    sendResetPasswordEmail(email)
      .then(() => {})
      .catch();
  }

  return (
    <Row
      style={{
        backgroundImage: "linear-gradient(#edf5fa, #c3cfe2)",
        minHeight: "100vh",
      }}
    >
      <Col
        xs={{ span: 24, offset: 0 }}
        sm={{ span: 12, offset: 6 }}
        xl={{ span: 8, offset: 8 }}
        style={{}}
      >
        <div
          className="loginContainer"
          style={{
            width: "400px",
            backgroundColor: "#fff",
            borderRadius: "10px",
          }}
        >
          <div style={{ textAlign: "center" }}>
            <img
              src={`https://quartoblobv2.blob.core.windows.net/shared/image/ess/ESS-logo.png`}
              width="250px"
              alt="logo"
              style={{ marginTop: "50px" }}
            />
          </div>

          {showSuccessMsg ? (
            <Form style={{ maxWidth: 340, margin: "0 auto" }}>
              <Form.Item>
                <Title level={4} style={{ textAlign: "center" }}>
                  {t("forgotPasswordForm.check_your_email")}
                </Title>
              </Form.Item>
              <Paragraph style={{ textAlign: "center" }}>
                {t("forgotPasswordForm.email_sent_message_1")}
                <b>{data.email}</b>
                {t("forgotPasswordForm.email_sent_message_2")}
              </Paragraph>
              <Paragraph style={{ textAlign: "center" }}>
                {t("forgotPasswordForm.email_sent_message_3")}
              </Paragraph>
            </Form>
          ) : (
            <Form
              form={form}
              onFinish={handleSubmit}
              onFinishFailed={handleSubmitFailed}
              className="login-form"
              style={{ maxWidth: 340, margin: "0 auto" }}
            >
              <Form.Item>
                <Title level={4} style={{ textAlign: "center" }}>
                  {t("forgotPasswordForm.forgot_your_password")}
                </Title>
              </Form.Item>
              <Paragraph style={{ textAlign: "center" }}>
                {t("forgotPasswordForm.enter_your_email_address_message")}
              </Paragraph>
              {showErrorMsg ? (
                <Alert
                  message={t("general.invalid_email")}
                  type="error"
                  afterClose={handleClose}
                  showIcon
                  style={{ marginBottom: 24 }}
                />
              ) : null}
              <Form.Item
                name={"email"}
                rules={[
                  {
                    type: "email",
                    message: t("general.email_input_validation"),
                  },
                  {
                    required: true,
                    message: t("general.email_required_validation"),
                  },
                ]}
                initialValue={data.email}
              >
                <Input
                  size="large"
                  prefix={<UserOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
                  placeholder={t("general.email_placeholder")}
                  onChange={handleClose}
                />
              </Form.Item>
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={isLoading}
                  onClick={enterLoading}
                  style={{
                    width: "100%",
                    height: 40,
                    fontSize: 16,
                  }}
                >
                  {t("general.submit")}
                </Button>
              </Form.Item>
              <div>
                <Button type="link" style={{ padding: 0 }} href="/login">
                  <ArrowLeftOutlined />
                  {t("general.back_to_login")}
                </Button>
              </div>
            </Form>
          )}

          <div style={{ textAlign: "center", paddingBottom: "25px" }}>
            <br></br>
            {t("general.copyright")}
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default ForgotPasswordForm;
