import { handleResponse, handleError, authHeader } from "./apiUtils";
import axios from "axios";
import { API_ROOT, ENVIRONMENT } from "./api-config";

const base = API_ROOT + "clinicSubmission/";
const env = ENVIRONMENT === "qa" ? "qaa" : ENVIRONMENT;

export function getAllClinicVisitByUser(currentYear, currentMonth = 0, isViewAllUser = false) {
  return axios({
    method: "GET",
    url: base + "GetAllClinicVisitByUser",
    headers: authHeader(),
    params: { year: currentYear, month: currentMonth, isViewAllUser: isViewAllUser },
  })
    .then(handleResponse)
    .catch(handleError);
}

export function getClinicVisit(clinicTransKey) {
  return axios({
    method: "GET",
    url: base + "GetClinicVisit",
    headers: authHeader(),
    params: { key: clinicTransKey, environment: env },
  })
    .then(handleResponse)
    .catch(handleError);
}

export function checkIsMedicalLimitReached(cliniVisitDate) {
  return axios({
    method: "GET",
    url: base + "CheckIsMedicalLimitReached",
    headers: authHeader(),
    params: {
      cliniVisitDate: cliniVisitDate,
    },
  })
    .then(handleResponse)
    .catch(handleError);
}

export function addClinicVisit(clinicVisit) {
  return axios({
    method: "POST",
    url: base + "AddClinicVisit",
    headers: authHeader(),
    data: clinicVisit,
  })
    .then()
    .catch(handleError);
}

export function updateClinicVisit(clinicVisit) {
  return axios({
    method: "PUT",
    url: base + "UpdateClinicVisit",
    headers: authHeader(),
    params: { key: clinicVisit.ClinicTransKey },
    data: clinicVisit,
  })
    .then()
    .catch(handleError);
}

export function deleteClinicVisit(clinicTransKey) {
  return axios({
    method: "DELETE",
    url: base + "DeleteClinicVisit",
    headers: authHeader(),
    params: { key: clinicTransKey },
  })
    .then()
    .catch(handleError);
}

export function getDependant() {
  return axios({
    method: "GET",
    url: base + "GetDependant",
    headers: authHeader(),
  })
    .then(handleResponse)
    .catch(handleError);
}
